import {
  LinkAsButton,
  LinkAsButtonProps,
} from '@johnlewispartnership/wtr-ingredients/ingredients/LinkAsButton';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import { viewOrderURL } from 'constants/urls';

import AnchorLink from 'components/AnchorLink';

interface Props {
  button?: boolean;
  className?: string;
  forwardedRef?: { current: any };
  label?: string;
  orderId: string;
  theme?: LinkAsButtonProps['theme'];
  width?: LinkAsButtonProps['width'];
}

const ViewOrder = memo(
  ({
    button = false,
    className = undefined,
    forwardedRef = undefined,
    label = 'View order',
    orderId,
    theme = undefined,
    width = undefined,
  }: Props) => {
    const url = `${viewOrderURL(Number(orderId))}`;

    return button ? (
      <LinkAsButton
        component={Link}
        data-testid={`view order ${orderId}`}
        to={url}
        label={label}
        theme={theme}
        width={width}
        className={className}
      />
    ) : (
      <AnchorLink className={className} innerRef={forwardedRef} to={url}>
        {label}
      </AnchorLink>
    );
  },
);

ViewOrder.displayName = 'ViewOrderButton';

export default ViewOrder;
