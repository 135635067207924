import { Warning, WarningTriangle } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import SmallButton from '@johnlewispartnership/wtr-ingredients/ingredients/SmallButton';
import classNames from 'classnames';
import React, { ReactChild, ReactNode, memo, useMemo } from 'react';

import { GoToPaymentPageButton } from 'components/ResolveOrderPayment/GoToPaymentPageButton';
import ViewOrderButton from 'components/wdx/buttons/ViewOrder';
import { FailedPaymentResolutionType } from 'constants/FailedPaymentResolutionType';
import { OrderStatus } from 'constants/orderStatus';
import { seasonalCutOffDateTime } from 'utils/checkout/is-seasonal-slot-date';
import { OrderType, matchOrderType } from 'utils/checkout/order-type';
import { formatAmendCutOffDateTime } from 'utils/format-amend-cutoff-date';

import styles from './PendingOrderSummary.scss';

const getAmendMessage = (
  orderType: OrderType | null,
  isSeasonalSlot: boolean,
  amendOrderCutOff?: string,
) => {
  if (isSeasonalSlot) {
    return (
      <>
        Amend cut-off for Christmas Entertaining items is <b>{seasonalCutOffDateTime}</b>
      </>
    );
  }

  return matchOrderType<ReactChild>(
    orderType,
    {
      onGroceries: () => (
        <>
          You can amend your order until <b>{formatAmendCutOffDateTime(amendOrderCutOff)}</b>
        </>
      ),
      onEntertaining: () =>
        'Please check individual notice times before amending Entertaining orders',
      onGroceriesEntertaining: () => 'Notice times vary for Entertaining items',
    },
    null,
  );
};

interface MessageProps {
  amendable: boolean;
  status: OrderStatus;
  amendMessage: ReactNode;
  failedPaymentResolutionType: FailedPaymentResolutionType | null;
}

const Message = memo(
  ({ amendable, status, amendMessage, failedPaymentResolutionType }: MessageProps) => {
    if (status === 'PAYMENT_FAILED' && failedPaymentResolutionType !== null) {
      const content =
        failedPaymentResolutionType === FailedPaymentResolutionType.selfServe ? (
          'Payment failed. Please pay now to receive this order'
        ) : (
          <>
            Payment could not be taken for this order. To pay now, call us on <b>0203 932 4128</b>
          </>
        );

      return (
        <div className={styles.statusWithIcon}>
          <Warning className={styles.errorIcon} aria-hidden />
          <Typography data-testid="payment-error">{content}</Typography>
        </div>
      );
    }

    if (!amendable) {
      return (
        <div className={styles.statusWithIcon}>
          <WarningTriangle className={styles.warningIcon} aria-hidden />
          <Typography>
            Your order is being dispatched and can no longer be cancelled or amended
          </Typography>
        </div>
      );
    }

    if (!amendMessage) return null;

    return <Typography data-testid="amend-message">{amendMessage}</Typography>;
  },
);

export interface StatusAndMenuProps {
  amendable: boolean;
  amending: boolean;
  amendOrderCutOff?: string;
  onCancelChanges: () => void;
  orderId: string;
  isSeasonalSlot: boolean;
  orderType: OrderType | null;
  status: OrderStatus;
  failedPaymentResolutionType: FailedPaymentResolutionType | null;
}

function getAmendingContent(
  orderId: StatusAndMenuProps['orderId'],
  amendMessage: string | number | JSX.Element | null,
  onCancelChanges: StatusAndMenuProps['onCancelChanges'],
) {
  return (
    <section className={styles.amendingStatusText}>
      <Typography element="span" data-testid="amending-message">
        You’re currently amending this order
        <span className={styles.amendingMessageSeparator}>. </span>
      </Typography>
      <Typography element="span" data-testid="amend-message">
        {amendMessage}
      </Typography>
      <div className={styles.actions}>
        <SmallButton theme="secondaryLight" onClick={onCancelChanges} label="Cancel changes" />
        <ViewOrderButton className={styles.viewOrderLink} orderId={orderId} />
      </div>
    </section>
  );
}

function getNonAmendingContent(
  amendable: StatusAndMenuProps['amendable'],
  status: StatusAndMenuProps['status'],
  amendMessage: string | number | JSX.Element | null,
  orderId: StatusAndMenuProps['orderId'],
  failedPaymentResolutionType: FailedPaymentResolutionType | null,
): React.ReactNode {
  const canPayNow = failedPaymentResolutionType === FailedPaymentResolutionType.selfServe;

  return (
    <>
      <div className={styles.statusText}>
        <Message
          amendable={amendable}
          status={status}
          amendMessage={amendMessage}
          failedPaymentResolutionType={failedPaymentResolutionType}
        />
      </div>
      {canPayNow ? (
        <GoToPaymentPageButton customerOrderId={orderId} className={styles.payNowButton} />
      ) : null}
      <ViewOrderButton
        orderId={orderId}
        label="View order"
        className={styles.viewOrderButton}
        theme={canPayNow ? 'secondary' : 'primary'}
        button
      />
    </>
  );
}

const StatusAndMenu = ({
  amending,
  amendable,
  amendOrderCutOff,
  orderId,
  onCancelChanges,
  isSeasonalSlot,
  orderType,
  status,
  failedPaymentResolutionType,
}: StatusAndMenuProps) => {
  const amendMessage = useMemo(
    () => getAmendMessage(orderType, isSeasonalSlot, amendOrderCutOff),
    [orderType, isSeasonalSlot, amendOrderCutOff],
  );

  return (
    <div
      className={classNames(styles.statusAndMenu, {
        [styles.withPayNow]: failedPaymentResolutionType === FailedPaymentResolutionType.selfServe,
      })}
    >
      {amending
        ? getAmendingContent(orderId, amendMessage, onCancelChanges)
        : getNonAmendingContent(
            amendable,
            status,
            amendMessage,
            orderId,
            failedPaymentResolutionType,
          )}
    </div>
  );
};

export default StatusAndMenu;
