import { createSelector } from 'reselect';

import orderStatus from 'constants/orderStatus';

import amendingOrder from 'redux/modules/trolley/selectors/amending-order';
import { getCustomerOrderId } from 'redux/modules/sessions/selectors';
import { getStatus } from 'redux/modules/orders/selectors/get-order';

const orderIsActiveOrder = createSelector(
  [getCustomerOrderId, (state, orderId) => orderId],
  (customerOrderId, orderId) => customerOrderId === orderId,
);

const orderIsBeingAmended = createSelector(
  [amendingOrder, orderIsActiveOrder],
  (applicationIsAmending, orderIsActive) => applicationIsAmending && orderIsActive,
);

const statusIsAmending = createSelector(getStatus, status => status === orderStatus.AMENDING);

export const orderIsAmending = createSelector(
  [statusIsAmending, orderIsBeingAmended],
  (amendingStatus, isBeingAmended) => amendingStatus || isBeingAmended,
);
