import { SlotTypes } from 'constants/fulfilmentTypes';
import { getCustomerOrderById } from 'redux/modules/entities/selectors/customer-orders';
import { createSelector } from 'reselect';
import { OrderType, orderTypes } from 'utils/checkout/order-type';
import { getSlotType } from './get-order';

export const getOrderTypeById = createSelector(
  getCustomerOrderById,
  getSlotType,
  (order: { containsEntertainingLines: boolean }, slotType): OrderType | null => {
    if (!order || !slotType || slotType === SlotTypes.UNDEFINED) {
      return null;
    }

    if (slotType === SlotTypes.ENTERTAINING_COLLECTION) {
      return orderTypes.ENTERTAINING;
    }

    if (order.containsEntertainingLines) {
      return orderTypes.GROCERIES_ENTERTAINING;
    }

    return orderTypes.GROCERIES;
  },
);
